/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        mobileDetect();
        landscapePortrait();
        mediaQueryStates();
        AOS.init({
          easing: 'ease-in-out-cubic',
          offset: 150,
          disable: 'mobile',
          duration: 800
        });
        $('.match_height').matchHeight();
        var data_href = $('*[data-href]');
        data_href.on('click', function(e) {
          var self = $(this);
          var data_href = self.attr('data-href');

          if (data_href.length > 0) {
            window.location.href = data_href;
          }
        });


        /*---------------------------------------*\
                  Resize Window
        \*---------------------------------------*/

        $(window).on('resizeend', function() {
          if (!$('body.mobile').length) {
            landscapePortrait();
            mediaQueryStates();
          }
        });
        $(window).resize(function() {});

        /*---------------------------------------*\
           Change device orientation
        \*---------------------------------------*/

        $(window).on('orientationchange', function() {
          if ($('body.mobile').length) {
            landscapePortrait();
            mediaQueryStates();
          }
        });
      },
      menu: function() {

        var body = $('body'),
            toggle = $('#toggle'),
            menu = $('#navigation'),
            menu_item = $('#navigation ul > li'),
            menucount = 0;

        toggle.on('click', function(e) {

          if (++menucount % 2 === 1) {

            body.addClass('menu_active');
            menu.velocity('slideDown', { duration:500, easing: 'easeOutSine' });
            menu_item.velocity('transition.slideUpIn', { duration: 700, delay: 500, stagger: 100, });
            $(this).addClass('on');
            $(this).addClass('is-active');
          } else {
            menu_item.velocity('transition.slideDownOut', { duration:300, stagger: 100, });
            menu.velocity('slideUp', { duration:300, delay: 500, easing: 'easeOutSine' });
            $(this).removeClass('on');
            $(this).removeClass('is-active');
            setTimeout(function() {
              body.removeClass('menu_active');
            }, 500);
          }
          e.preventDefault();
        });
        // Select all links with hashes
        $('a[href*="#"]')
        // Remove links that don't actually link to anything
          .not('[href="#"]')
          .not('[href="#0"]')
          .click(function (event) {
            // On-page links
            if (
              location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
              &&
              location.hostname == this.hostname
            ) {
              // Figure out element to scroll to
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
              // Does a scroll target exist?
              if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 1000, function () {
                });
              }
            }
          });
      },
      slicks: function() {
        $('.section_imgslider').slick({
          slidesToShow: 3,
          variableWidth: true,
          speed: 800,
          arrows: false,
          dots: false,
        });
      },
      finalize: function() {
        $('body').addClass('loaded');
      }
},
  // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');
      UTIL.fire('common', 'menu');
      UTIL.fire('common', 'slicks');
      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


/*---------------------------------------*\
   Functions
\*---------------------------------------*/

function mobileDetect() {
  var body = $('body');
  if (navigator.userAgent.toLowerCase().match(/iphone/i) || navigator.userAgent.toLowerCase().match(/ipod/i)) {
    body.addClass('mobile');
  } else if (navigator.userAgent.toLowerCase().match(/ipad/i)) {
    body.addClass('mobile tablet');
  } else {
    if (navigator.userAgent.toLowerCase().match(/android/i) ||
      navigator.userAgent.toLowerCase().match(/webos/i) ||
      navigator.userAgent.toLowerCase().match(/blackberry/i) ||
      navigator.userAgent.toLowerCase().match(/windows phone/i) ||
      navigator.userAgent.toLowerCase().match(/mobile/i)
    ) {
      if (navigator.userAgent.toLowerCase().match(/mobile/i)) {
        body.addClass('mobile');
      } else {
        body.addClass('mobile tablet');
      }
    } else {
      body.addClass('hover-on');
      body.addClass('transition-on');
    }
  }
  if (!!navigator.userAgent.match(/Trident\/7\./)) {
    body.addClass('ie');
  }
}

function landscapePortrait() {
  setTimeout(function() {
    var body = $('body');
    body.removeClass('landscape');
    body.removeClass('portrait');
    if ($('body.mobile').length) {
      if (window.innerHeight > window.innerWidth) {
        body.addClass('portrait');
      } else {
        body.addClass('landscape');
      }
    }
  }, 500);
}

function mediaQueryStates() {
  var body = $('body');
  // init state
  if (!$('.responsive_states').length) {
    body.append('<div class="responsive_states"><div class="visible-xxs" data-screen="screen_xxs"></div><div class="visible-xs" data-screen="screen_xs"></div><div class="visible-sm" data-screen="screen_sm"></div><div class="visible-md" data-screen="screen_md"></div><div class="visible-lg" data-screen="screen_lg"></div></div>');
  }
  // remove previous state classes
  body.removeClass(function(index, css) {
    return (css.match(/(^|\s)screen_\S+/g) || []).join(' ');
  });
  // chceck visible item and add class
  var responsiveStates = $('.responsive_states');
  var visibleItem = responsiveStates.find('div:visible').attr('data-screen');
  body.addClass(visibleItem);
}
